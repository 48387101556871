import { useContext, useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../../static/scss/home.scss";
import { useRecoilValue } from "recoil";

import CustomButton from "../../components/button/button";
import DragNDrop from "../../components/dragNDrop/DragNDrop";
import { useDragNDrop } from "../../components/dragNDrop/useDragNDrop";
import Modal, { IModal } from "../../components/modal/Modal";
import { ServiceInfoPannels } from "../../components/serviceInfoPannels/ServiceInfoPannels";
import CustomTable from "../../components/table/customTable/CustomTable";
import useTable from "../../components/table/customTable/hook/useTable";
import { taskManagementContext } from "../../context/TaskManagementContext";
import { useHeader } from "../../hook/useHeader";
import { serviceUsableState } from "../../recoil/services.atom";
import { convertSize } from "../../services/tools";
import { ReactComponent as IconTaskList } from "../../static/icons/home/tasklist.svg";
import { ReactComponent as IconFolder } from "../../static/icons/tasks/icon-folder.svg";

const DropZone = () => {
    const { inputRef, dragging } = useDragNDrop();
    const { t } = useTranslation();

    return (
        <div
            className={`d-lg-flex h-100 gap-5 flew-row justify-content-center justify-content-lg-between ${dragging ? "pe-none" : ""}`}
        >
            <div className="d-none d-lg-block home__dropzone__drag-preview">
                <div className="home__dropzone__drag-preview__drag-content h-100 w-100 d-flex align-items-center justify-content-center">
                    <h2 className="color-white fst-italic">{t("home.drop-zone.drop-your-file")}</h2>
                </div>
            </div>
            <div className="d-none home__dropzone__image-container gap-3 d-lg-flex flex-lg-column align-items-lg-center justify-content-lg-center">
                <div className="home__dropzone__image-container__img"></div>
                <span className="color-white fst-italic">{t("home.drop-zone.drag-here")}</span>
            </div>
            <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between align-items-start gap-2">
                <h2 className="home__dropzone__title">
                    {<Trans i18nKey={`home.drop-zone.title`} components={{ span: <span /> }} />}
                </h2>
                <span>{t("home.drop-zone.select-media")}</span>
                <span>{t("home.drop-zone.supported-files")}</span>
                <span className="mb-3 mb-lg-0">{t("home.drop-zone.size-limitation")}</span>
                <div className="w-100 d-flex justify-content-center justify-content-lg-end align-items-end gap-3">
                    <span className="d-none d-lg-block color-white fst-italic">
                        {t("home.drop-zone.select-from-device")}
                    </span>
                    <CustomButton
                        onClick={() => inputRef?.current?.click()}
                        style={{ position: "initial" }}
                        buttonText={t("home.drop-zone.select-file")}
                        customClass="btn-tasks"
                        icon={<IconFolder fill="white" width={16} />}
                    />
                </div>
            </div>
        </div>
    );
};

const TrialInfoCard = () => {
    const { getTrialState, loading } = useHeader();
    const { t } = useTranslation();
    const { push } = useHistory();

    const totalConsumption = 104_857_600; // 100 MB in bytes
    const consumptionLeft = getTrialState();
    const consumptionUsedPercentage = 100 - (consumptionLeft / totalConsumption) * 100;

    // Function to determine the color of the progress bar
    const getProgressBarColor = (percentage: number) => {
        if (percentage >= 80) return "#F50A6B";
        if (percentage >= 60) return "#FED340";
        return "#6B7897";
    };

    return (
        <div className="d-flex flex-column gap-2">
            <h2 className="color-secondary">{t("home.trial-info-card.title")}</h2>
            <div className="d-flex flex-column home__trial-info-card gap-3">
                <span className="home__trial-info-card__laius">
                    <Trans
                        i18nKey={
                            consumptionUsedPercentage >= 80
                                ? "home.trial-info-card.laius-limitation"
                                : "home.trial-info-card.laius"
                        }
                        components={{ strong: <strong />, br: <br /> }}
                    />
                </span>
                {!loading && (
                    <div className="d-flex flex-column gap-1">
                        <div className="trial-progress-bar">
                            <div
                                className="trial-progress-bar__fill"
                                style={{
                                    width: `${Math.min(consumptionUsedPercentage, 100)}%`,
                                    backgroundColor: getProgressBarColor(consumptionUsedPercentage)
                                }}
                            ></div>
                        </div>
                        <div className="trial-progress-info">
                            <span>
                                {convertSize(consumptionLeft)} {t("home.trial-info-card.remaining")}
                            </span>
                        </div>
                        <div className="d-flex justify-content-end">
                            <CustomButton
                                buttonType="button"
                                buttonText="Update plan"
                                onClick={() => push("/account/plan")}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const MediumTaskList = () => {
    const { t } = useTranslation();
    const canUseTask = useRecoilValue(serviceUsableState);
    const [tableLoading, setTableLoading] = useState(true);
    const { initTable, body } = useTable();
    const taskManagerStore = useContext(taskManagementContext);
    const { push } = useHistory();

    useEffect(() => {
        taskManagerStore.handleRefetch(true);
    }, []);

    useEffect(() => {
        setTableLoading(taskManagerStore.isLoading);
        if (!taskManagerStore.isLoading && taskManagerStore.historyCallsTable) {
            const data = { ...taskManagerStore.historyCallsTable };

            if (body && data && data.body) {
                let tempHead = [...data.head];
                tempHead = tempHead.map((h) => ({ ...h, title: "" }));
                initTable({ ...data, body: data.body.splice(0, 5), head: tempHead });
                setTableLoading(false);
            }
        }
    }, [taskManagerStore]);

    return (
        <div className="d-flex flex-column">
            <h2 className="color-secondary">{t("home.tasks-list.title")}</h2>
            <CustomTable
                customClassname={canUseTask.status === false ? "table-disabled no-head" : "no-head"}
                loading={tableLoading}
            />
            {body && body.length > 0 && (
                <div className="d-flex justify-content-end">
                    <CustomButton
                        onClick={() => push("/task-manager")}
                        style={{ position: "initial" }}
                        buttonText={t("home.tasks-list.go-to-task-list")}
                        customClass="btn-main task-list"
                        icon={<IconTaskList fill="white" width={16} />}
                    />
                </div>
            )}
        </div>
    );
};

export const Home = () => {
    const { isTrial } = useHeader();
    const history = useHistory();
    const [dragEnter, setDragEnter] = useState(false);
    const canUseTask = useRecoilValue(serviceUsableState);
    const dropzoneDisabled = !canUseTask.status;
    const [modalState, setModalState] = useState({} as IModal);
    const { clearDropZone, error } = useDragNDrop();

    const handleDragEnter = (v: boolean) => {
        setDragEnter(v);
    };

    if (canUseTask.loading) return null;
    return (
        <>
            <div className="d-flex flex-column home gap-4">
                {!dropzoneDisabled && !canUseTask.loading && (
                    <DragNDrop
                        disabled={dropzoneDisabled}
                        onDragEnter={() => handleDragEnter(true)}
                        onDragLeave={() => handleDragEnter(false)}
                        onDrop={() => {
                            history.push("/task-manager/anonymization");
                        }}
                        onError={(error) => {
                            setModalState({
                                title: error.title,
                                message: error.message,
                                show: true,
                                onConfirm: () => {
                                    setModalState({ ...{}, show: false });
                                    clearDropZone();
                                    handleDragEnter(false);
                                }
                            });
                        }}
                    >
                        <div
                            className={`home__dropzone ${dragEnter && !error ? "home__dropzone--dragging" : ""} ${dropzoneDisabled ? "home__dropzone--disabled" : ""}`}
                        >
                            <DropZone />
                        </div>
                    </DragNDrop>
                )}
                <ServiceInfoPannels tags={"blurit"} />
                {isTrial && (
                    <div className="middle-container flex-grow-1">
                        <TrialInfoCard />
                    </div>
                )}
                <div className="middle-container flex-grow-1">
                    <MediumTaskList />
                </div>
            </div>
            <Modal {...modalState} />
        </>
    );
};

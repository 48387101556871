import { useQuery } from "@tanstack/react-query";

import {
    GetServiceInfo,
    getServiceInfo,
    ServiceInfoTag,
    ServiceInfoLevel
} from "../../api/serviceInfo.api";

export const useGetServiceInfo = (tags: ServiceInfoTag[] | ServiceInfoTag) => {
    const { data, isLoading, error } = useQuery({
        queryKey: ["service-info"],
        queryFn: getServiceInfo
    });

    const isDateDefined = (info: GetServiceInfo) => info.fromTime || info.toTime;

    const bluritInfos = data?.filter(
        (info) =>
            (info.tags.some((tag) => tags.includes(tag)) && !isDateDefined(info)) ||
            (isDateDefined(info) &&
                new Date() >= new Date(info.fromTime!) &&
                new Date() <= new Date(info.toTime!))
    );

    const prepareDefaultTitleFromLevel = (level: ServiceInfoLevel) => {
        switch (level) {
            case "info":
                return "Info";
            case "warning":
                return "Warning";
            case "alert":
                return "Alert";
        }
    };

    const formatData = (data: GetServiceInfo[]) => {
        return data.map((info) => ({
            ...info,
            title: info?.title?.en || prepareDefaultTitleFromLevel(info.level),
            message: info?.message?.en || "No message defined",
            fromTime: new Date(info.fromTime!),
            toTime: new Date(info.toTime!)
        }));
    };

    return {
        isLoading,
        error,
        bluritInfos: formatData(bluritInfos || [])
    };
};

import React, { useRef, useState, useMemo } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import useWindowDimensions from "../../../hook/useWindowDimensions";
import { DownloadButton } from "../../../pages/account/utils/downloadButton";
import { BadgeStatus } from "../../../pages/taskManager/helper/formatTable";
import { serviceUsableState, TASKS_UNAVAILABLE_TYPES } from "../../../recoil/services.atom";
import { convertSize } from "../../../services/tools";

// Icons
import { ReactComponent as IconBack } from "../../../static/icons/icon-back-mobile.svg";
import { ReactComponent as IconEdit } from "../../../static/icons/icon_edit.svg";

// Style
import "./TaskManagerDetail.scss";
import {
    FormattedHistoryCall,
    HistoryCallServiceMode,
    ServiceSearchKey,
    TaskStatusEnum
} from "../../../types/historyCall";
import Modal, { IModal } from "../../modal/Modal";

type TTask = {
    title: string;
    data: string;
    status?: boolean;
};

const TaskManagerDetail: React.FC = () => {
    const { t } = useTranslation();
    const { state }: { state: { element: FormattedHistoryCall } } = useLocation();
    const { push } = useHistory();
    const { width } = useWindowDimensions();
    const [modalState, setModalState] = useState({} as IModal);
    const jsonAnchorRef = useRef<HTMLAnchorElement>(null);
    const fileAnchorRef = useRef<HTMLAnchorElement>(null);
    const canUseTask = useRecoilValue(serviceUsableState);

    const task = state.element;

    const getBadgeStatus = () => {
        switch (task.status) {
            case TaskStatusEnum.SUCCEEDED:
            case "Deleted":
                return BadgeStatus.SUCCESS;
            case TaskStatusEnum.SENT:
            case TaskStatusEnum.STARTED:
                return BadgeStatus.PROCESSING;
            case TaskStatusEnum.FAILED:
            case TaskStatusEnum.UPLOAD_FAILED:
                return BadgeStatus.FAILED;
            default:
                return BadgeStatus.NONE;
        }
    };

    const formatFileName = (element: FormattedHistoryCall) => {
        if (element.status === TaskStatusEnum.SUCCEEDED && element.filename) {
            const nameWithoutExtension = element.filename.slice(
                0,
                element.filename.lastIndexOf(".")
            );
            const extension = element.filename.slice(element.filename.lastIndexOf("."));
            const formattedDate = moment(element.date).utc().format("YYYYMMDDhhmmss");
            return `${nameWithoutExtension}_processed_${formattedDate}z${extension}`;
        }
        return element.filename;
    };

    const isTaskSucceeded = (status: string) => status === TaskStatusEnum.SUCCEEDED;
    const isTaskStudio = (mode: HistoryCallServiceMode) =>
        mode === HistoryCallServiceMode.DETECTION || mode === HistoryCallServiceMode.RENDER;

    const displayModal = () => {
        setModalState({
            show: true,
            message: t("task-management-detail.modal.studio"),
            onConfirm: () => setModalState({ ...modalState, show: false })
        });
    };

    const startDownload = (ref: React.RefObject<HTMLAnchorElement>) => {
        ref.current?.click();
    };

    const taskList: TTask[] = useMemo(
        () => [
            { title: t("task-management-detail.list.status"), data: task.status, status: true },
            {
                title: t("task-management-detail.list.date"),
                data: moment(task.date).format("DD-MM-YYYY_HH:mm:ss")
            },
            { title: t("task-management-detail.list.service"), data: task.service_name },
            { title: t("task-management-detail.list.fileType"), data: task.file_type },
            { title: t("task-management-detail.list.fileName"), data: formatFileName(task) },
            {
                title: t("task-management-detail.list.fileSize"),
                data: convertSize(Number(task.size))
            }
        ],
        [task, t]
    );

    if (width > 992) {
        push("/task-manager");
        return null;
    }

    const isDisabled = (condition: boolean) =>
        (!canUseTask.status && canUseTask.reason === TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES) ||
        condition;

    return (
        <>
            <h2 className="title-up-container" onClick={() => push("/task-manager")}>
                <IconBack fill="#6B7897" />
                {t("task-management-detail.title")}
            </h2>

            <div className="middle-container middle-container-detail">
                <ul className="middle-container-detail-list">
                    {taskList.map((element, index) => (
                        <li key={index}>
                            <h5 className="list-title">{element.title}</h5>
                            {element.status ? (
                                <div className="task-status">
                                    <span className={`status ${getBadgeStatus()}`}></span>
                                    {element.data}
                                </div>
                            ) : (
                                element.data
                            )}
                        </li>
                    ))}
                    <li className="item-buttons">
                        <h5 className="list-title">{t("task-management-detail.list.downloads")}</h5>
                        <div className="list-grp">
                            {isTaskStudio(task.mode) ? (
                                <div
                                    className={`mobile-task ${isDisabled(!isTaskSucceeded(task.status) || task.mode === HistoryCallServiceMode.RENDER) ? "disabled" : ""}`}
                                    onClick={displayModal}
                                >
                                    <IconEdit width={20} height={20} fill={"#000000"} />
                                    {t("task-management-detail.button.studio")}
                                </div>
                            ) : (
                                <div
                                    className={`mobile-task ${isDisabled(!isTaskSucceeded(task.status) || !task.parameters.output_detections_url) ? "disabled" : ""}`}
                                    onClick={() => startDownload(jsonAnchorRef)}
                                >
                                    <DownloadButton
                                        params={{
                                            searchKey: task.service_search_key,
                                            job_id: task.job_id,
                                            fileType: task.file_type,
                                            is_json: true,
                                            fileName: `${task.filename.slice(0, task.filename.lastIndexOf("."))}.json`
                                        }}
                                        url={`${process.env.REACT_APP_API_URL}/task-management/${task.fileurl}`}
                                        filename={`${task.filename.slice(0, task.filename.lastIndexOf("."))}.json`}
                                        buttonType="image"
                                        aRef={jsonAnchorRef}
                                    />
                                    {t("task-management-detail.button.coordinates")}
                                </div>
                            )}
                            <div
                                className={`mobile-task ${isDisabled(!isTaskSucceeded(task.status)) ? "disabled" : ""}`}
                                onClick={() => startDownload(fileAnchorRef)}
                            >
                                <DownloadButton
                                    params={{
                                        searchKey: task.service_search_key,
                                        job_id: task.job_id,
                                        fileType: task.file_type,
                                        fileName: formatFileName(task)
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${task.fileurl}`}
                                    filename={formatFileName(task)}
                                    buttonType="image"
                                    aRef={fileAnchorRef}
                                />
                                {task.service_search_key === ServiceSearchKey.ANONYMIZATION
                                    ? t("task-management-detail.button.blurred-file")
                                    : t("task-management-detail.button.watermarked-file")}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <Modal {...modalState} />
        </>
    );
};

export default TaskManagerDetail;

import React from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import CustomButton from "../../../../components/button/button";
import { ErrorAccountCardExpired } from "../../../../components/error/ErrorAccountCardExpired";
import { Client } from "../../../../context/clientContext";
import { useAxios } from "../../../../services/hook/requestsService";
import { isCardExpired } from "../../../../services/tools";
import { License } from "../../../../types/license";

export const PaymentInformation: React.FC<{
    clientValues: Client | null;
    licensesValues: License[] | null;
}> = ({ clientValues }) => {
    const { t } = useTranslation();
    const { putData } = useAxios();

    const submitUpdateCard = async () => {
        const response = await putData(null, `client/payment-method`, undefined);
        window.location = response.data.url;
    };

    return (
        <div className="middle-container my-account-view">
            <div
                className={`w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5${
                    isCardExpired(clientValues?.creditCardExpirationDate) ? " mb-5" : ""
                }`}
            >
                <div className="d-flex  align-items-center">
                    <h2 className="mb-0 me-lg-4 account-title">
                        {t("account-management.cb-information")}
                    </h2>
                </div>
                <div className="d-flex align-items-center">
                    {isCardExpired(clientValues?.creditCardExpirationDate) && (
                        <div className="me-lg-4 expired-content">
                            <ErrorAccountCardExpired />
                        </div>
                    )}

                    <CustomButton
                        classNameType="mainWhite"
                        onClick={submitUpdateCard}
                        buttonText={t("account-management.update-cb")}
                        buttonType="button"
                    />
                </div>
            </div>
            <div className="row flex-row mb-0 mb-lg-4">
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-md-start">
                        <span className="mr-1 text-bold">{t("account-management.cb-number")}</span>
                        <span
                            className={`account-value ${
                                isCardExpired(clientValues?.creditCardExpirationDate)
                                    ? "color-error"
                                    : ""
                            }`}
                        >
                            {clientValues?.cardLast4
                                ? `XXXX XXXX XXXX ${clientValues?.cardLast4}`
                                : "-"}
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-md-start">
                        <span className="mr-1 text-bold">
                            {t("account-management.cb-expiration-date")}
                        </span>
                        <span
                            className={`account-value ${
                                isCardExpired(clientValues?.creditCardExpirationDate)
                                    ? "color-error"
                                    : ""
                            }`}
                        >
                            {clientValues?.creditCardExpirationDate
                                ? moment(clientValues?.creditCardExpirationDate).format("MM/YY")
                                : "-"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row flex-row mb-0 mb-lg-4">
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-md-start">
                        <span className="mr-1 text-bold">
                            {t("account-management.cb-currency")}
                        </span>
                        <span className="account-value">
                            {clientValues?.currency === "EUR" ? "Euros (EUR)" : "Dollars (USD)"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

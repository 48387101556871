import { useContext, useEffect, useState } from "react";

import { AxiosProgressEvent } from "axios";
import { Formik } from "formik";
import "../../../../static/scss/anonymization.scss";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { boolean, mixed, object } from "yup";

import { BlurOptions, optionsSettingsState } from "./components/BlurOptions";
import { dragNDropContext } from "../../../../components/dragNDrop/DragNDropContext";
import { useDragNDrop } from "../../../../components/dragNDrop/useDragNDrop";
import Modal, { IModal } from "../../../../components/modal/Modal";
import { taskManagementContext } from "../../../../context/TaskManagementContext";
import { useGetClient } from "../../../../hook/api/client";
import { useAskAnonymization } from "../../../../hook/queries/useAnonymization";
import { useHeader } from "../../../../hook/useHeader";
import { convertSize } from "../../../../services/tools";
import { BlurTypeKey, BlurTypeKeys, FormAskAnonymization } from "../../../../types";

const defaultValues = {
    activation_faces_blur: true,
    activation_plates_blur: true,
    output_detections_url: false,
    blur_type: "blur" as BlurTypeKey,
    with_mail: false,
    input_media: undefined,
    anonymization_mode: undefined
};

// see https://github.com/jquense/yup/issues/176#issuecomment-367352042 to explain implementation
const taskValidationSchema = () =>
    object().shape(
        {
            activation_faces_blur: boolean()
                .required()
                .when("activation_plates_blur", {
                    is: (activation_plates_blur: boolean) => !activation_plates_blur,
                    then: boolean().oneOf([true], "At least one needs to be checked")
                }),
            activation_plates_blur: boolean()
                .required()
                .when("activation_faces_blur", {
                    is: (activation_faces_blur: boolean) => !activation_faces_blur,
                    then: boolean().oneOf([true], "At least one needs to be checked")
                }),
            with_mail: boolean().notRequired(),
            input_media: mixed().required(),
            output_detections_url: boolean().notRequired(),
            blur_type: mixed().required().oneOf(Object.values(BlurTypeKeys))
        },
        [
            ["activation_faces_blur", "activation_plates_blur"],
            ["activation_plates_blur", "activation_faces_blur"]
        ]
    );

const initialModalState: IModal = {
    show: false
};

export const Anonymization = () => {
    const { t } = useTranslation();
    const reseStepFormState = useResetRecoilState(optionsSettingsState);
    const history = useHistory();
    const [modalState, setModal] = useState<IModal>({ show: false });
    const { getTrialState, loading: trialLoading } = useHeader();
    const { data: clientData } = useGetClient();
    const [progress, setProgress] = useState<number>();
    const [progressDetails, setProgressDetails] = useState<string>();
    const [loading, setLoading] = useState(false);
    const context = useContext(dragNDropContext);
    const tasksContext = useContext(taskManagementContext);
    const { file, clearDropZone } = useDragNDrop();
    const setStep = useSetRecoilState(optionsSettingsState);
    // ce useState + le useEffect en dessous sont là pour gérer la partie de redirection depuis la page Home
    const [initialValues, setInitialValues] = useState<
        Omit<typeof defaultValues, "input_media"> & { input_media: File | undefined }
    >(defaultValues);
    // ce use state regarde au moment où on monte le composant si un fichier à été déposé (depuis la partie home)
    // si oui on le set dans initialValues puis on met a jour les steps pour que le composant d'option s'affiche en active
    useEffect(() => {
        if (file !== undefined) {
            setInitialValues((prev) => ({ ...prev, input_media: file }));
            setStep({
                "select-file": {
                    state: "active"
                },
                "blur-settings": {
                    state: "active"
                }
            });
        }
    }, [file]);

    // reset recoil state when we leave the page
    useEffect(() => {
        return () => {
            clearDropZone();
            reseStepFormState();
        };
    }, []);

    useEffect(() => {
        if (!context.state.error) return;
        setModal({
            ...modalState,
            show: true,
            title: context.state.error?.title,
            message: context.state.error.message,
            onConfirm: () => setModal({ ...modalState, show: false }),
            showCancel: false,
            progress: null,
            loading: undefined
        });
    }, [context.state.error]);

    const handleProgressUpload = (progressEvent: AxiosProgressEvent) => {
        const progressLoaded = progressEvent.loaded;
        const progressTotal = (progressEvent as any).total;
        const percentCompleted = (progressLoaded * 100) / progressTotal;
        if (percentCompleted == progress) return;
        setProgress(percentCompleted);
        if (progressLoaded < progressTotal) {
            setProgressDetails(convertSize(progressLoaded) + " / " + convertSize(progressTotal));
        } else {
            setProgress(undefined);
            setProgressDetails(undefined);
        }
    };

    const mutation = useAskAnonymization(handleProgressUpload);

    const handleSubmit = (data: FormAskAnonymization) => {
        setLoading(true);
        setModal({ ...modalState, withCheckBox: false, show: true });
        mutation.mutate(
            { ...data },
            {
                onSuccess: () => {
                    setLoading(false);
                    tasksContext.handleRefetch(true);

                    const successMessage = data.with_mail
                        ? t("task-management.tasks.modal.success.messageWithMail")
                        : t("task-management.tasks.modal.success.messageWithoutMail");

                    setModal({
                        ...modalState,
                        show: true,
                        title: t("task-management.tasks.modal.success.title"),
                        message: successMessage,
                        onConfirm: () => {
                            history.goBack();
                            setModal({ ...modalState, show: false });
                        },
                        showCancel: false,
                        confirmBtnText: t("task-management.tasks.modal.success.cancel-button"),
                        progress: null,
                        loading: undefined
                    });
                },
                onError: () => {
                    setLoading(false);
                    setProgress(undefined);
                    setModal({
                        ...modalState,
                        show: true,
                        message: t("error.internalError"),
                        onConfirm: () => setModal({ ...modalState, show: false }),
                        showCancel: false,
                        progress: null,
                        loading: undefined
                    });
                }
            }
        );
    };

    const onConfirmTask = (data: FormAskAnonymization) => {
        let modalParams = {
            show: true,
            confirmBtnText: t("task-management.tasks.modal.confirm.accept-button")
        } as IModal;
        const file = data.input_media as File;
        if (clientData?.isTrial && getTrialState() - file.size < 0) {
            modalParams = {
                ...modalParams,
                showCancel: false,
                message: t("error.trialLimitation-message"),
                title: t("error.trialLimitation-title"),
                onConfirm: () => setModal({ ...modalState, show: false })
            };
        } else {
            modalParams = {
                ...modalParams,
                showCancel: true,
                title: t("task-management.tasks.modal.confirm.title"),
                message: (
                    <Trans
                        i18nKey={"task-management.tasks.modal.confirm.message"}
                        values={{
                            fileName: data.input_media?.name,
                            fileSize: convertSize(data.input_media?.size ?? 0)
                        }}
                        components={{ strong: <strong /> }}
                    />
                ),
                onCancel: () => setModal({ ...modalState, show: false }),
                onConfirm: (checkboxValue: boolean) => {
                    handleSubmit({ ...data, with_mail: checkboxValue });
                },
                confirmBtnText: t("task-management.tasks.modal.confirm.accept-button"),
                cancelBtnText: t("task-management.tasks.modal.confirm.cancel-button"),
                withCheckBox: true
            };
        }
        setModal({ ...modalParams, progress, loading });
    };

    const onCancelTask = () => {
        setModal({ ...initialModalState, progress: null, loading: undefined, show: false });
        history.goBack();
    };
    return (
        <div className="task-manager__container">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={taskValidationSchema}
                validateOnMount={true}
                enableReinitialize={true}
            >
                <>
                    <BlurOptions
                        onConfirmTask={
                            clientData?.isTrial && trialLoading ? undefined : onConfirmTask
                        }
                        onCancelTask={onCancelTask}
                    />
                    <Modal
                        title={t("task-management.tasks.modal.upload.title")}
                        progress={progress && Math.round(progress)}
                        loading={progress === undefined && loading}
                        message={
                            loading
                                ? t("task-management.tasks.modal.upload.message", { icon: "⚠️ " })
                                : modalState.message
                        }
                        footerData={
                            progressDetails && (
                                <span className="label-text">
                                    <Trans
                                        i18nKey={"task-management.tasks.modal.upload.progress"}
                                        values={{
                                            progressDetails: progressDetails
                                        }}
                                    />
                                </span>
                            )
                        }
                        {...modalState}
                    />
                </>
            </Formik>
        </div>
    );
};

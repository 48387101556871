import React, { useContext, useEffect, useState } from "react";

import { dashboardContext } from "../context/DashboardContext";

import "../static/scss/dashboard.scss";
import TaskButton from "../components/mobile/task/TaskButton";

const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dashboardStore = useContext(dashboardContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            dashboardStore.refetchData(true);
            setIsLoaded(true);
        }
    }, [dashboardStore, isLoaded, setIsLoaded]);

    return (
        <div className="">
            {/* TODO: En attente de confirmation avant de le supprimer */}
            {/* <div className='mb-4'>
        <Collapse title={t("dashboard.services-general-state")} isOpen={!isLoaded}>
          <DashboardTable />
        </Collapse>
      </div> */}
            <div className="middle-container">{children}</div>
            <TaskButton />
        </div>
    );
};

export default DashboardLayout;

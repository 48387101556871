import React, { useContext, useEffect, useState } from "react";

import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

import {
    pluginOs,
    PluginOsEntry,
    PluginEditorEntry,
    pluginEditors,
    PLUGIN_FILES_BASE_URL
} from "./config";
import "../../../static/scss/plugin.scss";
import { checkFileExists, readFileContent } from "../../../api";
import { ServiceInfoPannels } from "../../../components/serviceInfoPannels/ServiceInfoPannels";
import { licensesContext } from "../../../context/licensesContext";
import { getUserTypeOS } from "../../../services/tools";
import { ReactComponent as BluritIcon } from "../../../static/icons/icon-anonymization-table.svg";
import { ReactComponent as AE } from "../../../static/icons/plugin/AE.svg";
import { ReactComponent as Avid } from "../../../static/icons/plugin/Avid.svg";
import { ReactComponent as PR } from "../../../static/icons/plugin/PR.svg";

export const Ressources: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="ressources">
            <div>
                <div className="intro">
                    <div className="intro__title">
                        <BluritIcon fill="white" width={30} height={30} />
                        <h2>
                            <Trans
                                i18nKey="ressources.title"
                                components={{ link: <strong></strong> }}
                            />
                        </h2>
                    </div>
                    <p>{t("ressources.laius")}</p>
                </div>
                <PluginsDiv editors={pluginEditors} />
            </div>
            <ServiceInfoPannels tags={"plugin"} />
            <ProgressBarTimeOut />
        </div>
    );
};

export const ProgressBarTimeOut: React.FC = () => {
    const { t } = useTranslation();
    const { data: licenses } = useContext(licensesContext);
    const today = moment();

    if (
        !licenses ||
        !licenses.length ||
        moment.utc(licenses[0].dateStart).add(7, "days") <= today
    ) {
        return null;
    }

    const trialDuration = 7;
    const startingDate = moment.utc(licenses[0].dateStart);
    const endOfTrial = startingDate.add(trialDuration, "days");
    const diffDays = endOfTrial.diff(today, "days") + 1;

    const listItems = Array.from({ length: trialDuration }, (_, index) => index + 1).map(
        (number) => {
            const isPast = number > diffDays;
            const isLastPast = number === diffDays + 1;
            return (
                <div
                    key={number}
                    className={`section ${isPast ? "past" : ""} ${isLastPast ? "last-past" : ""}`}
                    style={{
                        height: `${100 / trialDuration}%`,
                        backgroundColor: isPast ? "#47DC95" : ""
                    }}
                ></div>
            );
        }
    );

    return (
        <section className="progressbar">
            <h4>{t(`date.count${diffDays > 1 ? "s" : ""}`, { count: diffDays })}</h4>
            <h4 className="subtitle">{t("ressources.countdown.left")}</h4>
            <div className="period">
                <div className="progress-container">
                    <div className={`progress${diffDays === trialDuration ? " beginning" : ""}`}>
                        {listItems}
                    </div>
                </div>
                <div className="legend">
                    <div>
                        <h5>{t("ressources.countdown.start-trial", { count: trialDuration })}</h5>
                        <p>
                            <small>{t("ressources.countdown.install-test")}</small>
                        </p>
                    </div>
                    <div>
                        <h5>{endOfTrial.format("DD MMM YYYY")}</h5>
                        <p>
                            <small>{t("ressources.countdown.unless-cancel")}</small>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

const installerFileName = (
    editor: PluginEditorEntry,
    os: PluginOsEntry,
    version: string
): string => {
    return `BlurIt-${editor.installerSlug}-${os.installerSlug}-${version}.${os.installerExt}`;
};

const editorIcons = (editor: PluginEditorEntry): React.ReactElement[] => {
    switch (editor.tag) {
        case "adobe":
            return [<AE key="ae-icon" />, <PR key="pr-icon" />];
        case "avid":
            return [<Avid key="avid-icon" />];
        default:
            return [];
    }
};

interface PluginsDivProps {
    editors: PluginEditorEntry[];
}
export const PluginsDiv: React.FC<PluginsDivProps> = ({ editors }) => {
    const { t } = useTranslation();
    const [resourceProps, setResourceProps] = useState<PluginResourceProps[] | null>(null);

    useEffect(() => {
        const defineResourceProps = async () => {
            const userOs = getUserTypeOS();
            const pluginResources: PluginResourceProps[] = [];
            for (const editor of editors) {
                const availableInstallers: any[] = [];
                for (const os of pluginOs) {
                    // Check version for editor and OS
                    const versionFilepath = `${PLUGIN_FILES_BASE_URL}/${editor.tag}-${os.type}`;
                    const version = await readFileContent(versionFilepath);
                    if (version === null) {
                        // No version file for this editor and OS
                        break;
                    }
                    // Check installer file presence for editor, OS and version
                    const filepath = `${PLUGIN_FILES_BASE_URL}/${installerFileName(editor, os, version)}`;
                    const available = await checkFileExists(filepath);
                    if (available) {
                        const osLabel = t(`ressources.os.${os.type}`);
                        availableInstallers.push({
                            primaryLabel: `${osLabel} Version ${version} .${os.installerExt}`,
                            secondaryLabel: `Or ${osLabel} .${os.installerExt}`,
                            filepath: filepath,
                            matchUserOs: os.type === userOs
                        });
                    }
                }

                // No installer available, skip this editor
                if (availableInstallers.length === 0) {
                    break;
                }

                // Define bloc title
                const editorTitle = t(`ressources.${editor.tag}.name`);

                // Define documentation link if available
                const documentation =
                    editor.docPath !== undefined
                        ? {
                              label: t(`ressources.${editor.tag}.documentation`),
                              path: editor.docPath
                          }
                        : undefined;

                // When only one installer is available, show it as primary download
                if (availableInstallers.length === 1) {
                    const installer = availableInstallers[0];
                    const pluginResource: PluginResourceProps = {
                        title: editorTitle,
                        primaryDownload: {
                            label: installer.primaryLabel,
                            icons: editorIcons(editor),
                            filepath: installer.filepath
                        },
                        documentation: documentation
                    };
                    pluginResources.push(pluginResource);
                    break;
                }

                // When several installers are available, show the one matching the user OS as primary download
                availableInstallers.sort(
                    (a, b) => (b.matchUserOs ? 1 : 0) - (a.matchUserOs ? 1 : 0)
                );
                const primaryInstaller = availableInstallers[0];
                const secondaryInstaller = availableInstallers[1];
                const pluginResource: PluginResourceProps = {
                    title: editorTitle,
                    primaryDownload: {
                        label: primaryInstaller.primaryLabel,
                        icons: editorIcons(editor),
                        filepath: primaryInstaller.filepath
                    },
                    secondaryDownload: {
                        label: secondaryInstaller.secondaryLabel,
                        filepath: secondaryInstaller.filepath
                    },
                    documentation: documentation
                };
                pluginResources.push(pluginResource);
            }
            setResourceProps(pluginResources);
        };
        defineResourceProps();
    }, [editors]);

    if (resourceProps == null) {
        return <div className="intro intro__loading">{t("ressources.loading")}</div>;
    }

    return (
        <div className="plugins">
            {resourceProps.map((item, key) => (
                <PluginResourceDiv key={key} {...item} />
            ))}
        </div>
    );
};

interface PluginResourceProps {
    title: string;
    primaryDownload: {
        label: string;
        icons: React.ReactElement[];
        filepath: string;
    };
    secondaryDownload?: {
        label: string;
        filepath: string;
    };
    documentation?: {
        label: string;
        path: string;
    };
}

export const PluginResourceDiv: React.FC<PluginResourceProps> = (pluginResource) => {
    return (
        <div className="plugin">
            <h3>{pluginResource.title}</h3>
            <div className="actions">
                {pluginResource.primaryDownload && (
                    <>
                        <div className="download">
                            <a
                                className="button btn btn-main download__main"
                                href={pluginResource.primaryDownload.filepath}
                                target="_blank"
                                rel="noreferrer"
                                role="button"
                                download
                            >
                                {pluginResource.primaryDownload.icons}
                                {pluginResource.primaryDownload.label}
                            </a>
                            {pluginResource.secondaryDownload && (
                                <>
                                    <a
                                        className="download__other"
                                        href={pluginResource.secondaryDownload.filepath}
                                        target="_blank"
                                        rel="noreferrer"
                                        download
                                    >
                                        {pluginResource.secondaryDownload.label}
                                    </a>
                                </>
                            )}
                        </div>
                        {pluginResource.documentation && (
                            <div className="documentation">
                                <a
                                    className="button btn btn-white-main"
                                    href={pluginResource.documentation.path}
                                    title={pluginResource.documentation.label}
                                    target="_blank"
                                    rel="noreferrer"
                                    role="button"
                                >
                                    {pluginResource.documentation.label}
                                </a>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

import React, { useContext, useState } from "react";

import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";

import { customCalendarContext } from "../../../context/calendarContext";
import { convertSize } from "../../../services/tools";
import { ReactComponent as ArrowSVG } from "../../../static/icons/icon-arrow-calendar.svg";
import { ReactComponent as CloseSvg } from "../../../static/icons/icon-menu-close.svg";

import "../../../static/scss/calendar-mobile.scss";

type MobileCalendarProps = object;

export const MobileCalendar: React.FC<MobileCalendarProps> = () => {
    const { t } = useTranslation();
    const { handleChangeYear, show, handleShow, selectOptions, globalState } =
        useContext(customCalendarContext);
    const [monthToDisplay, setMonthToDisplay] = useState(moment().format("YYYY-MM"));

    if (!show) {
        return null;
    }
    const dataType = selectOptions.value;

    const onClose = () => handleShow(false);

    const getDataPerMonth = (key: string) => {
        const data = globalState.elemToDisplay.months.find((d: any) => d.month === monthToDisplay);
        if (data) {
            return dataType === "consumptionPerYear" ? `${convertSize(data[key])}` : data[key];
        }
        return "-";
    };

    const handleChangeMonth = (action: "-" | "+") => {
        const month = monthToDisplay.split("-")[1];
        let newMonth = monthToDisplay;
        if (parseInt(month) > 1) {
            if (action === "-") {
                newMonth = moment(monthToDisplay).subtract(1, "M").format("YYYY-MM");
            } else {
                newMonth = moment(monthToDisplay).add(1, "M").format("YYYY-MM");
            }
        } else {
            if (action === "-") {
                handleChangeYear("-");
                newMonth = moment(monthToDisplay).subtract(1, "M").format("YYYY-MM");
            } else {
                handleChangeYear("+");
                newMonth = moment(monthToDisplay).add(1, "M").format("YYYY-MM");
            }
        }
        setMonthToDisplay(newMonth);
    };

    const isButtonDisabled = () => {
        return monthToDisplay === moment().format("YYYY-MM");
    };

    return (
        //@ts-ignore
        <SweetAlert
            closeOnClickOutside={true}
            onConfirm={() => null}
            title=""
            showConfirm={false}
            onCancel={onClose}
            show={show}
            customClass="mobile-calendar w-100"
        >
            <div className="close mb-4">
                <CloseSvg height={20} width={20} onClick={onClose} />
            </div>
            <h1>
                {dataType === "apiCallsPerYear"
                    ? t("dashboard.service-usage")
                    : t("dashboard.service-consumption")}
            </h1>
            <div className="d-flex w-100 align-items-center justify-content-between mb-4 navigation">
                <ArrowSVG
                    fill="#47dc95"
                    height={30}
                    width={30}
                    className="arrow-year mr-2"
                    onClick={() => handleChangeMonth("-")}
                />
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <h2 className="mb-0">
                        {moment(monthToDisplay.split("-")[1], "MM").format("MMMM")}&nbsp;
                        {monthToDisplay.split("-")[0]}
                    </h2>
                </div>
                <ArrowSVG
                    fill="#47dc95"
                    height={40}
                    width={30}
                    className={`arrow-year arrow-reversed ml-2 ${isButtonDisabled() ? "disabled" : ""}`}
                    onClick={() => handleChangeMonth("+")}
                />
            </div>
            <div className="months-data">
                <h3>{moment(monthToDisplay.split("-")[1], "MM").format("MMMM")}</h3>
                {dataType === "identityPerYear" ? (
                    <>
                        <div className="d-flex justify-content-between">
                            <label className="mr-2">Models</label>
                            <span>{getDataPerMonth("model")}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <label className="mr-2">Vectors</label>
                            <span>{getDataPerMonth("vectors")}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="d-flex justify-content-between">
                            <label className="mr-2">Total</label>
                            <span>{getDataPerMonth("total")}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <label className="mr-2">Picture</label>
                            <span>{getDataPerMonth("image")}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <label className="mr-2">Video</label>
                            <span>{getDataPerMonth("video")}</span>
                        </div>
                    </>
                )}
            </div>
        </SweetAlert>
    );
};

import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import CustomButton from "../../../components/button/button";
import { Client } from "../../../context/clientContext";
import { serviceUsableState } from "../../../recoil/services.atom";

export const PlanAndOptions: React.FC<{ clientValues: Client | null }> = ({ clientValues }) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const serviceUsable = useRecoilValue(serviceUsableState);
    const handleNavigation = (key: string) => {
        push(key);
    };

    const getPlan = () => {
        if (clientValues?.isTrial) {
            return t("account-management.plan-trial");
        } else {
            return t("account-management.plan-pay-as-you-go");
        }
    };

    const getSupport = () => {
        switch (clientValues?.support) {
            case "BASIC": {
                return t("account-management.plan-basic");
            }
            case "STANDARD": {
                return t("account-management.plan-standard");
            }
            case "PREMIUM": {
                return t("account-management.plan-premium");
            }
            case "PRO": {
                return t("account-management.plan-pro");
            }
            default: {
                return clientValues?.isTrial ? t("account-management.plan-basic") : "-";
            }
        }
    };

    return (
        <div className="middle-container my-account-view">
            <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                <div className="d-flex align-items-center">
                    <h2 className="account-title mb-0 me-lg-4">
                        {t("account-management.plan-option")}
                    </h2>
                </div>
                <CustomButton
                    classNameType="mainWhite"
                    onClick={() => handleNavigation("/account/plan/update")}
                    buttonText={t("account-management.update-plan")}
                    buttonType="button"
                />
            </div>
            <div className="d-flex flex-column gap-3">
                <div className="row align-items-center">
                    <div className="col-3 col-lg-2">
                        <span className="mr-1 text-bold">{t("account-management.plan-type")}</span>
                    </div>
                    <div className="col-8">
                        <span className="account-value">
                            <div className="d-block plan-container">{getPlan()}</div>
                        </span>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-3 col-lg-2">
                        <span className="mr-1 text-bold">{t("account-management.support")}</span>
                    </div>
                    <div className="col-8">
                        <span className="account-value">
                            <div className="d-block plan-container">{getSupport()}</div>
                        </span>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-3 col-lg-2">
                        <span className="mr-1 text-bold">{t("account-management.blurit-api")}</span>
                    </div>
                    <div className="col-8">
                        <span className="account-value">
                            <div className="d-block plan-container text-uppercase">
                                {serviceUsable.status
                                    ? t("account-management.enable")
                                    : t("account-management.disable")}
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { getData } from "../services/axios.services";

export const ServiceInfoLevels = ["info", "warning", "alert"] as const;
export type ServiceInfoLevel = (typeof ServiceInfoLevels)[number];

export const ServiceInfoTags = [
    "blurit",
    "wis",
    "facelytics",
    "website",
    "app",
    "plugin",
    "api",
    "studio"
] as const;
export type ServiceInfoTag = (typeof ServiceInfoTags)[number];

export type GetServiceInfo = {
    title?: {
        en: string;
        fr?: string;
    };
    message?: {
        en: string;
        fr?: string;
    };
    level: ServiceInfoLevel;
    tags: Array<ServiceInfoTag>;
    fromTime?: Date;
    toTime?: Date;
};

export const getServiceInfo = async () => {
    return (
        await getData<GetServiceInfo[]>({
            url: "/service-info"
        })
    ).data;
};

import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";

import { useButtonsDisabled } from "./hooks/useButtonsDisabled";
import { updateAnonymizatioDetections } from "../../../../../../api";
import Modal, { IModal } from "../../../../../../components/modal/Modal";
import { useAskAnonymizationRendering } from "../../../../../../hook/queries/useAnonymization";
import {
    AnonymizationDetection,
    AnonymizationType,
    FormUpdateDetections
} from "../../../../../../types";
import { useAutoDetections } from "../../hooks/useAutoDetections";
import { DimensionRatio, useCanvasMedia } from "../../hooks/useCanvasMedia";
import { useFramesTimestamps } from "../../hooks/useFramesTimestamp";
import { useMedia } from "../../hooks/useMedia";
import { DetectionCoordinatesRange, useTracks } from "../../hooks/useTracks";
import { Box, DetectionClassName, Point } from "../../recoil/framesDetectionsCoordinates.atom";

const assertValues = (data: number) => {
    return data < 0 ? 0 : data > 1 ? 1 : data;
};

export const _convertFromCanvasToFrameBoxDimension = (
    box: Box,
    dimensionsRatio: DimensionRatio
): AnonymizationDetection.DetectionBox => {
    const { srcElementHeight, srcElementWidth, centerShift_x, centerShift_y, ratio } =
        dimensionsRatio;
    const frameWidth = srcElementWidth * ratio;
    const frameHeight = srcElementHeight * ratio;
    const { x, y, width, height } = box;
    const l = assertValues((x - centerShift_x) / frameWidth);
    const t = assertValues((y - centerShift_y) / frameHeight);
    const r = assertValues(l + width / frameWidth);
    const b = assertValues(t + height / frameHeight);

    return { l, t, r, b };
};

export const _convertFromCanvasToFrameLdmDimension = (
    ldm: Point[],
    dimensionsRatio: DimensionRatio
) => {
    const { srcElementHeight, srcElementWidth, centerShift_x, centerShift_y, ratio } =
        dimensionsRatio;
    const frameWidth = srcElementWidth * ratio;
    const frameHeight = srcElementHeight * ratio;
    if (!ldm) return [];
    return ldm.map(({ x, y }) => {
        const newX = assertValues((x - centerShift_x) / frameWidth);
        const newY = assertValues((y - centerShift_y) / frameHeight);

        return {
            x: newX,
            y: newY
        };
    });
};

export const useApiCalls = (): {
    saveDetections: () => Promise<unknown>;
    askRendering: (withMail: boolean) => Promise<unknown>;
} => {
    const { jobId } = useParams() as { jobId: string };
    const { mutate: saveDetectionsMutation } = useMutation(updateAnonymizatioDetections);
    const { mutate: askRenderingMutation } = useAskAnonymizationRendering(() => {});

    const [tracks] = useTracks();
    const [autoDetectionsState] = useAutoDetections();
    const [framesTimestamps] = useFramesTimestamps();
    const [{ dimensionRatio }] = useCanvasMedia();

    const _formatData2 = (): FormUpdateDetections => {
        if (!dimensionRatio) throw new Error("");
        const formattedDetections: FormUpdateDetections = {
            framesSorted: {
                frames: {},
                tracks: {}
            },
            jobId: jobId
        };
        const activeTracks = tracks.tracks.filter((track) => track.active);
        // format tracks objet
        const tracksFormatted: Record<string, AnonymizationDetection.DetectionsTrack> = {};
        for (const trackKey in activeTracks) {
            const track = activeTracks[trackKey];
            tracksFormatted[track.trackId] = {
                ffrm: track.frameStart,
                lfrm: track.frameEnd
            };
        }
        // assign formatted tracks to final object
        formattedDetections.framesSorted["tracks"] = tracksFormatted;
        // format frame object
        // get the min firstFrame
        const minFrame = Math.min(...activeTracks.map((track) => track.frameStart));
        // get the max lastFrame
        const maxFrame = Math.max(...activeTracks.map((track) => track.frameEnd));
        // get only active tracks
        const frames: Record<string, AnonymizationDetection.DetectionsFrame> = {};
        for (let currentFrame = minFrame; currentFrame <= maxFrame; currentFrame++) {
            const frameDetections: AnonymizationDetection.Detection[] = [];
            //-----------------------------------------//
            // cas où la track n'a pas de frame saved mais qu'elle a un frame range (une new detection qui n'a pas été modifiée APRES avoir été créée)
            const notModifiedNewDetections = activeTracks
                .filter(
                    (track) =>
                        currentFrame >= track.frameStart &&
                        currentFrame <= track.frameEnd &&
                        track.type === "new" &&
                        track.savedRange.length === 0
                )
                .reduce(
                    (acc, curr) => {
                        const currentFrameInRange: DetectionCoordinatesRange | undefined =
                            curr.initialRange.find(
                                (t) => currentFrame >= t.frameStart && currentFrame <= t.frameEnd
                            );
                        if (!currentFrameInRange) return acc;

                        return [
                            ...acc,
                            {
                                coordinates: {
                                    box: currentFrameInRange.box,
                                    ldm: currentFrameInRange.ldm
                                },
                                trackId: curr.trackId,
                                src: 0.9,
                                cn: curr.cn
                            }
                        ];
                    },
                    [] as {
                        coordinates: { box: Box; ldm: Point[] };
                        trackId: number;
                        src: number;
                        cn: DetectionClassName;
                    }[]
                );

            notModifiedNewDetections.forEach((d) => {
                frameDetections.push({
                    box: _convertFromCanvasToFrameBoxDimension(d.coordinates.box, dimensionRatio),
                    ldm: _convertFromCanvasToFrameLdmDimension(d.coordinates.ldm, dimensionRatio),
                    src: d.src,
                    cn: d.cn as unknown as AnonymizationType,
                    trk: d.trackId
                });
            });
            //-----------------------------------------//
            // cas ou c'est une nouvelle track qui a été modifiée
            const modifiedNewDetections = activeTracks
                .filter(
                    (track) =>
                        currentFrame >= track.frameStart &&
                        currentFrame <= track.frameEnd &&
                        track.type === "new" &&
                        track.savedRange.length > 0
                )
                .reduce(
                    (acc, curr) => {
                        const currentFrameInSavedRange: DetectionCoordinatesRange | undefined =
                            curr.savedRange.find(
                                (t) => currentFrame >= t.frameStart && currentFrame <= t.frameEnd
                            );
                        if (!currentFrameInSavedRange) {
                            return [
                                ...acc,
                                {
                                    coordinates: {
                                        box: curr.initialRange[0].box,
                                        ldm: curr.initialRange[0].ldm
                                    },
                                    trackId: curr.trackId,
                                    src: 0.9,
                                    cn: curr.cn
                                }
                            ];
                        }
                        return [
                            ...acc,
                            {
                                coordinates: {
                                    box: currentFrameInSavedRange.box,
                                    ldm: currentFrameInSavedRange.ldm
                                },
                                trackId: curr.trackId,
                                src: 0.9,
                                cn: curr.cn
                            }
                        ];
                    },
                    [] as {
                        coordinates: { box: Box; ldm: Point[] };
                        trackId: number;
                        src: number;
                        cn: DetectionClassName;
                    }[]
                );

            modifiedNewDetections.forEach((d) => {
                frameDetections.push({
                    box: _convertFromCanvasToFrameBoxDimension(d.coordinates.box, dimensionRatio),
                    ldm: _convertFromCanvasToFrameLdmDimension(d.coordinates.ldm, dimensionRatio),
                    src: d.src,
                    cn: d.cn as unknown as AnonymizationType,
                    trk: d.trackId
                });
            });
            //-----------------------------------------//
            // cas où c'est une auto detection
            const autoDetections = activeTracks
                .filter(
                    (track) =>
                        currentFrame >= track.frameStart &&
                        currentFrame <= track.frameEnd &&
                        track.type === "auto"
                )
                .reduce(
                    (acc, curr) => {
                        const currentFrameInSavedRange = curr.savedRange.find(
                            (t) => currentFrame >= t.frameStart && currentFrame <= t.frameEnd
                        );
                        if (!currentFrameInSavedRange) {
                            const foundDetection = autoDetectionsState.frames[currentFrame].find(
                                (dtc) => dtc.trackId === curr.trackId
                            );
                            if (!foundDetection) return acc;
                            return [
                                ...acc,
                                {
                                    coordinates: {
                                        box: foundDetection.box,
                                        ldm: foundDetection.ldm
                                    },
                                    trackId: curr.trackId,
                                    src: foundDetection.src,
                                    cn: curr.cn
                                }
                            ];
                        }
                        return [
                            ...acc,
                            {
                                coordinates: {
                                    box: currentFrameInSavedRange.box,
                                    ldm: currentFrameInSavedRange.ldm
                                },
                                trackId: curr.trackId,
                                src: 0.9,
                                cn: curr.cn
                            }
                        ];
                    },
                    [] as {
                        coordinates: { box: Box; ldm: Point[] };
                        trackId: number;
                        src: number;
                        cn: DetectionClassName;
                    }[]
                );
            autoDetections.forEach((d) => {
                frameDetections.push({
                    box: _convertFromCanvasToFrameBoxDimension(d.coordinates.box, dimensionRatio),
                    ldm: _convertFromCanvasToFrameLdmDimension(d.coordinates.ldm, dimensionRatio),
                    src: d.src,
                    cn: d.cn as unknown as AnonymizationType,
                    trk: d.trackId
                });
            });
            //-----------------------------------------//
            // assign dtcs object to the current frame index
            frames[currentFrame] = {
                ts: framesTimestamps.framesTimestamps[currentFrame].timestamp,
                dtcs: frameDetections
            };
        }
        formattedDetections.framesSorted["frames"] = frames;
        return formattedDetections;
    };

    const saveDetections = () => {
        const data = _formatData2();
        return new Promise((resolve, reject) => {
            saveDetectionsMutation(data, {
                onError: (e) => {
                    reject(e);
                },
                onSuccess: () => resolve("File saved")
            });
        });
    };

    const askRendering = (withMail: boolean) => {
        const data = _formatData2();
        return new Promise((resolve, reject) => {
            askRenderingMutation(
                { ...data, with_mail: withMail },
                { onError: (e) => reject(e), onSuccess: () => resolve("Export ok") }
            );
        });
    };

    return { saveDetections, askRendering };
};

export const HeaderActions = () => {
    const [mediaState] = useMedia();
    const { saveDetections, askRendering } = useApiCalls();
    const [modalState, setModalState] = useState({} as IModal);
    const { goBack } = useHistory();
    const disabled = useButtonsDisabled();

    const handleCloseModal = () => setModalState({ show: false });

    const openErrorModal = () => {
        setModalState({
            show: true,
            message: "An error occured.",
            onConfirm: handleCloseModal,
            confirmBtnText: "Close",
            loading: false
        });
    };

    const handleClickSave = () => {
        const modal = {
            show: true,
            message: `You are about to update the detection file. This update will permanently overwrite your previous detections. Please confirm.`,
            showCancel: true,
            onCancel: () => handleCloseModal(),
            cancelBtnText: "Cancel",
            confirmBtnText: "Save detection file",
            onConfirm: async () => {
                setModalState((prev) => ({
                    ...prev,
                    withCheckBox: false,
                    loading: true,
                    message: `Your detection file is being uploaded on Blurit server. Please don’t quit this screen until the upload is completed.`
                }));
                try {
                    await saveDetections();
                    handleCloseModal();
                } catch (e) {
                    handleCloseModal();
                    openErrorModal();
                }
            }
        };
        setModalState(modal);
    };

    const handleClickExport = () => {
        const modal = {
            show: true,
            message: `You are about to generate the final blurred file. Once this action is started, you will no longer be able to edit your file.`,
            showCancel: true,
            onCancel: () => setModalState({ show: false }),
            cancelBtnText: "Cancel",
            confirmBtnText: "Export",
            withCheckBox: true,
            onConfirm: async (checkboxValue: boolean) => {
                setModalState((prev) => ({
                    ...prev,
                    withCheckBox: false,
                    loading: true,
                    message: `Your picture / video is being uploaded on Blurit server. Please don’t quit this screen until the upload is completed.`
                }));
                try {
                    await askRendering(checkboxValue);
                    handleCloseModal();
                    goBack();
                } catch (e) {
                    handleCloseModal();
                    openErrorModal();
                }
            }
        };
        setModalState(modal);
    };

    return (
        <>
            <div className="header-actions">
                <span className="header-actions__filename">{mediaState?.filename}</span>
                <div className="header-actions__buttons-container">
                    <button
                        disabled={disabled}
                        onClick={handleClickSave}
                        className="header-actions__buttons-container__button-save"
                    >
                        <span>Save</span>
                    </button>
                    <button
                        disabled={disabled}
                        onClick={handleClickExport}
                        className="header-actions__buttons-container__button-export"
                    >
                        <span>Export</span>
                    </button>
                </div>
            </div>
            <Modal {...modalState} />
        </>
    );
};

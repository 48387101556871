import React, { useState, useEffect } from "react";

import { Form, Formik, FormikErrors } from "formik";
import { withCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { setAuthCookie } from "../../../api/authApi";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/Input";
import RoundedLoader from "../../../components/loader/RoundedLoaderAnimated";
import { getError } from "../../../services/errorsService";
import { useAxios } from "../../../services/hook/requestsService";
import { useQuery } from "../../../services/hook/useQuery";

// Extract validation schema
const passwordValidationSchema = yup.object().shape({
    password: yup.string().test("password", "", function (value) {
        const errors: string[] = [];
        if (!value || value.length < 8) errors.push("atLeast8Char");
        if (!value || !/\d/.test(value)) errors.push("atLeast1Number");
        if (!value || !/[A-Z]/.test(value)) errors.push("atLeast1Uppercase");
        if (!value || !/[a-z]/.test(value)) errors.push("atLeast1Lowercase");
        if (!value || !/[#?!@$%^&*\-+().]/.test(value)) errors.push("atList1SpecialChar");
        return errors.length > 0
            ? this.createError({ path: this.path, message: JSON.stringify(errors) })
            : true;
    }),
    passwordConfirmation: yup.string().test("passwordConfirmation", "", function (value) {
        const errors: string[] = [];

        if (!value || value !== this.parent.password) {
            errors.push("passwordSimilar");
        }
        return errors.length > 0
            ? this.createError({ path: this.path, message: JSON.stringify(errors) })
            : true;
    })
});

interface FormValues {
    password: string;
    passwordConfirmation: string;
}

const initialValues: FormValues = {
    password: "",
    passwordConfirmation: ""
};

const Instructions: React.FC<{ errors: FormikErrors<FormValues>; values: FormValues }> = ({
    errors,
    values
}) => {
    const { t } = useTranslation();

    const passwordInstructions = [
        "atLeast8Char",
        "atLeast1Lowercase",
        "atLeast1Uppercase",
        "atLeast1Number",
        "atList1SpecialChar"
    ];

    const isPasswordInstructionValid = (instruction: string) => {
        if (Object.keys(errors).length > 0) {
            const errorsPassword = errors?.password ? JSON.parse(errors.password) : [];
            return !errorsPassword.includes(instruction);
        }
        return true;
    };

    const isPasswordSimilarValid = () => {
        if (Object.keys(errors).length > 0) {
            const errorsPasswordConfirmation = errors?.passwordConfirmation
                ? JSON.parse(errors.passwordConfirmation)
                : [];
            return !errorsPasswordConfirmation.includes("passwordSimilar");
        }
        return true;
    };

    const shouldShowInstructions =
        values.password.length > 0 || values.passwordConfirmation.length > 0;
    const allPasswordInstructionsValid = passwordInstructions.every(isPasswordInstructionValid);

    return (
        <div id="validation-container" className="mb-3">
            {shouldShowInstructions && (
                <>
                    {passwordInstructions
                        .filter((instruction) => !isPasswordInstructionValid(instruction))
                        .map((instruction, index) => (
                            <p key={`${instruction}-${index}`} className="mb-1 invalid-text">
                                {t(`create-password.instructionsPassword.${instruction}`)}
                            </p>
                        ))}
                    {allPasswordInstructionsValid && !isPasswordSimilarValid() && (
                        <p className="mb-1 invalid-text">
                            {t("create-password.instructionsPassword.passwordSimilar")}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

const CreatePassword: React.FC = () => {
    const { t } = useTranslation();
    const { loading, getData, postData } = useAxios(); // attention regression token ?
    const [error, setError] = useState<string | null>(null);
    const [response, setResponse] = useState<{ email: string } | null>(null);
    const axiosActions = useAxios();
    const { push } = useHistory();

    //get code from url
    const query = useQuery();
    const code = query.get("code");

    useEffect(() => {
        if (code && !response) {
            (async () => {
                const { data, error } = await getData(null, "password_reset", {
                    params: { code }
                });
                setResponse(data);
                if (data) {
                    setError(null);
                } else {
                    if (error?.response?.status === 400) {
                        setError(t("create-password.error.tokenInvalid"));
                    } else {
                        setError(t("error.internalError"));
                    }
                }
            })();
        } else {
            //si pas de code set un token invalid
            setError(t("create-password.error.tokenInvalid"));
        }
        return () => {
            setResponse(null);
            setError(null);
        };
    }, [code, t]);

    //Check if password and password confirmation have the good validation rules
    const isFormValid = (errors: FormikErrors<FormValues>, values: FormValues): boolean =>
        Object.keys(errors).length > 0 || !values.password || !values.passwordConfirmation;

    //Send new password to api
    const submitPassword = async (values: FormValues) => {
        try {
            const { data } = await postData(null, `password_reset/?code=${code}`, values);
            if (data) {
                setAuthCookie(data);

                const { data: client } = await axiosActions.getData(null, "client");
                if (client.licenses && client.licenses.length > 0) {
                    push("/plugin/ressources");
                } else {
                    push("/home");
                }
            }
        } catch (e) {
            setError(e as string);
        }
    };

    if (loading && !error && !response) {
        return <RoundedLoader isLoading={loading} />;
    }

    if (error) {
        return (
            <div className="d-flex flex-column align-items-center">
                <div className="credential-error mb-4">
                    {getError(error, t, t("create-password.error.tokenInvalid")).message}
                </div>
                <CustomButton
                    onClick={() => push("/forgotten-password")}
                    buttonText={t("create-password.error.goBackButton")}
                    classNameType="mainRounded"
                />
            </div>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitPassword}
            validationSchema={passwordValidationSchema}
        >
            {({ values, handleChange, errors }) => {
                return (
                    <Form className="d-flex flex-column justify-content-between">
                        <Input
                            id="password"
                            name="password"
                            icon="password"
                            value={values.password}
                            placeholder={t("create-password.passwordPlaceholder")}
                            isRequired
                            onChange={handleChange}
                            inputType="password"
                            inputClassName="rounded"
                            labelTranslation={t("create-password.passwordLabel")}
                            maskable={true}
                        />
                        <Input
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            icon="password"
                            value={values.passwordConfirmation}
                            placeholder={t("create-password.passwordConfirmationPlaceholder")}
                            isRequired
                            onChange={handleChange}
                            inputType="password"
                            inputClassName="rounded"
                            labelTranslation={t("create-password.passwordConfirmationLabel")}
                            maskable={true}
                        />
                        {
                            <div className="d-flex justify-content-center mb-3 mt-3">
                                {loading ? (
                                    <RoundedLoader isLoading={loading} />
                                ) : (
                                    <CustomButton
                                        type="submit"
                                        disabled={isFormValid(errors, values)}
                                        buttonText={t("create-password.submit")}
                                        classNameType="mainRounded"
                                    />
                                )}
                            </div>
                        }
                        <Instructions errors={errors} values={values} />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withCookies(CreatePassword);

import { CanvasDetections } from "./canvasDetections/CanvasDetections";
import { VideoPreview } from "./mediaPreview/VideoPreview";
import { Toolbox } from "./toolbox/Toolbox";

import "../../styles/editor.scss";
import { RoundedLoader } from "../../../../../../components/loader/RoundedLoader";
import { useInvalidMedia } from "../../hooks/useInvalidMedia";
import { useLoadingState } from "../../hooks/useLoadingState";
import { isMediaVideo, useMedia } from "../../hooks/useMedia";
import { ImagePreview } from "./mediaPreview/ImagePreview";
import { BlurPreview } from "./mediaPreview/BlurPreview";

const Loading: React.FC<{ state: "init" | "canplay" | undefined }> = ({ state }) => {
    if (!state) return null;
    return (
        <div className="loading">
            <RoundedLoader size={50} isLoading={true} />
            {state === "init" && (
                <span className="color-clear-blue-grey text-center">
                    Your file is getting downloaded.
                    <br />
                    This action might take a few seconds.
                </span>
            )}
        </div>
    );
};

const MediaInvalid: React.FC<{ isInvalid: boolean }> = ({ isInvalid }) =>
    !isInvalid ? null : (
        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
            <span className="color-primary text-center">
                Due to specific encoding parameters, your file can&apos;t be edited in this Studio.
                <br />
                {
                    "We're sorry for the inconvenience. However, you can download the blurred file directly from the task list."
                }
                <br />
            </span>
        </div>
    );

export const MediaVisualizer = () => {
    const [mediaState] = useMedia();
    const [loadingState] = useLoadingState();
    const { mediaInvalid } = useInvalidMedia();
    const loaderState = () => {
        if (!loadingState || mediaInvalid) return;
        if (loadingState === "init-studio") return "init";
        if (loadingState === "media-loading") return "canplay";
    };

    return (
        <div className="studio-layout__container__editor__media-visualizer">
            <div className="studio-layout__container__editor__media-visualizer__tool-box">
                <Toolbox />
            </div>
            <div
                className={`studio-layout__container__editor__media-visualizer__content ${
                    !loadingState ? "filtered" : ""
                }`}
            >
                <Loading state={loaderState()} />
                <MediaInvalid isInvalid={mediaInvalid} />
                <div
                    style={{
                        aspectRatio: "16/9",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        position: "relative"
                    }}
                >
                    <BlurPreview />

                    {mediaState.isLoading ? null : isMediaVideo(mediaState) ? (
                        <VideoPreview />
                    ) : (
                        <ImagePreview />
                    )}
                    <CanvasDetections />
                </div>
            </div>
        </div>
    );
};

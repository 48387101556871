import { ServiceInfoTag } from "../../api/serviceInfo.api";
import { useGetServiceInfo } from "../../hook/queries/useServiceInfo";
import { ReactComponent as AlertIcon } from "../../static/icons/home/alert-icon.svg";
import { ReactComponent as InfoIcon } from "../../static/icons/home/info-icon.svg";
import { ReactComponent as WarningIcon } from "../../static/icons/home/warning-icon.svg";

export const ServiceInfoPannels: React.FC<{ tags: ServiceInfoTag[] | ServiceInfoTag }> = ({
    tags
}) => {
    const { bluritInfos, isLoading } = useGetServiceInfo(tags);

    if (isLoading || bluritInfos.length === 0) return null;
    return (
        <div className="d-flex flex-column">
            {bluritInfos.map((info, key) => (
                <div
                    key={key}
                    className="home__pannel px-3 py-3 middle-container d-flex flex-column gap-3"
                >
                    <div className="d-flex align-items-center gap-2">
                        {
                            {
                                info: <InfoIcon height={28} width={28} />,
                                warning: <WarningIcon height={28} width={28} />,
                                alert: <AlertIcon height={28} width={28} />
                            }[info.level]
                        }
                        <span className="home__pannel__title">{info.title}</span>
                    </div>

                    <p className="home__pannel__message">{info.message}</p>
                </div>
            ))}
        </div>
    );
};
